<div class="d-flex h-100 w-100">
  <!-- --------------left menu-------- -->
  <app-asset-sidebar *ngIf="backendService.currentUserData">
  </app-asset-sidebar>

  <!-- --------------right menu-------- -->
  <div class="w-100 bg">
    <headings class="headings py-2">
      <span class="mr-3 title">Members</span>
      <mat-form-field class="header-project-select" appearance="fill">
        <mat-select [(value)]="projectId" (selectionChange)="changeProject($event.value)">
          <mat-option [value]="project.id" *ngFor="let project of projects$ | async">{{ project.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <div class="d-flex summary mx-auto">
        <span>
          <mat-icon class="loader" *ngIf="uiService.projectInfo.loading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </mat-icon>
          <button type="button" matTooltip="Feature count"><img src="assets/icons/annotations.svg" class="mr-1">
            <b>{{uiService.projectInfo.annotations}}</b></button>

          <button type="button" matTooltip="Images"><img src="assets/icons/images.svg" class="mr-1">
            <b>{{uiService.projectInfo.images}}</b></button>

          <button type="button" matTooltip="Videos"><img src="assets/icons/video.svg" class="mr-1">
            <b>{{uiService.projectInfo.videos}}</b></button>

          <button type="button" matTooltip="Images with Features"><img src="assets/icons/annotated-images.svg"
              class="mr-1"> <b>{{uiService.projectInfo.annotatedImages}}</b></button>

          <button type="button" matTooltip="Images with no Features"><img src="assets/icons/unannotated-images.svg"
              class="mr-1"><b>{{uiService.projectInfo.unAnnotatedImages}}</b></button>

        </span>

      </div> -->

      <div class="d-flex side-right ml-auto">
        <button (click)="searchToggle()" [class.isActive]="isSearch" mat-raised-button class="mr-2 search">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-raised-button class="header-button mr-2" (click)="openDialog()" [disabled]="!projectId || getRoleForUserById(currentUserId) === 'approver'
      ||  getRoleForUserById(currentUserId) === 'viewer' 
          || getRoleForUserById(currentUserId) === 'reviewer'">
          <mat-icon class="mr-2">supervisor_account</mat-icon>
          <span class="text-uppercase">Add Member</span>
        </button>
      </div>
    </headings>

    <div class="flex flex-wrap justify-content-center" [style.flexGrow]="!(loading$ | async) ? '1' : '0'">
      <ng-container *ngIf="(!(loading$ | async)); else loading">
        <ng-container *ngIf="members.length && (projects$ | async)?.length">
          <div class="w-100  mt-3 ml-5 mr-5">

            <input *ngIf="isSearch" type="text" class="form-control table-form-control"
              placeholder="Filter by member email  prefix" (keyup)="getSearch($event)" [(ngModel)]="searchMembers">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="text-uppercase">Name</th>
                  <th scope="col" class="text-uppercase">Role</th>
                  <th scope="col" class="text-uppercase">Status</th>
                  <th scope="col" class="text-uppercase">Read Only</th>
                  <th scope="col" class="text-uppercase text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let member of slicedMembers">
                  <td>{{ member.email }}</td>
                  <td class="text-capitalize">
                    {{ getRoleForUser(member) }}
                    <span matTooltip='daily report' *ngIf="member?.dailyReport">
                      <img style="height: 20px;" src="assets/daily-report.png" />
                    </span>
                  </td>
                  <td class="text-capitalize">
                    <ng-container [ngSwitch]="member.status">
                      <ng-container *ngSwitchCase="'resend'">
                        <a (click)="reinvite(member)" style="cursor: pointer">Re-send invite</a>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{ member.status }}
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="pl-4">
                    <mat-checkbox (change)="toggleReadOnly(member)"
                      [disabled]="getRoleForUser(member) === 'viewer'  || getRoleForUser(member) === 'owner'  || getRoleForUser(member) == 'editor' || isCurrentUserReviewer() || isCurrentUserApprover() "
                      [checked]="member?.readonly"></mat-checkbox>
                  </td>
                  <td class="text-center actions">
                    <div class="d-flex align-items-center" style="height: 20px;">
                      <div
                        *ngIf="member.status ==='onboarded' &&  isCurrentUserOwner() && getRoleForUser(member) !== 'owner'"
                        matTooltip="Make owner" matTooltipClass="reports-tooltip" #menuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="menu" (click)="selectedMember = member"
                        [class.disabled]="member.status === 'invited'" class="mr-3"
                        style="cursor: pointer; width: 16px; height: 16px; position: relative;">
                        <img src="/assets/icons/switch-user.svg" alt="Make Owner" style="vertical-align: top;">
                      </div>

                      <mat-icon (click)="openChangeRoleDialog(member)" style="cursor: pointer;margin-right:10px"
                        [class.disabled]="getRoleForUser(member) ==='owner'" matTooltip="Change role"
                        *ngIf="(isCurrentUserOwner()  || isCurrentUserEditor()) && getRoleForUser(member) !== 'owner' "
                        class="rename-icon" aria-hidden="false">
                        edit
                      </mat-icon>

                      <mat-icon
                        [class.disabled]="isCurrentUserViewer() || isCurrentUserApprover() || isCurrentUserReviewer()"
                        *ngIf="getRoleForUser(member) !== 'owner'" (click)="deleteMember(member)"
                        style="cursor: pointer; width: 16px; height: 16px;" svgIcon="trash">
                      </mat-icon>
                      <mat-menu #menu="matMenu" xPosition="before">
                        <div class="mat-menu-content-inner" (click)="$event.stopPropagation()">
                          <p style="white-space: nowrap;">
                            Are you sure that you want to transfer the ownership? <br> Once it is transfered you won't
                            have
                            ownership's rights on the project.
                          </p>
                          <div class="d-flex justify-content-end">
                            <button mat-raised-button color="warn" (click)="closeMakeOwnerMenu()"
                              class="btn">No</button>
                            <button mat-raised-button color="primary" (click)="changeOwner(selectedMember)"
                              class="btn ml-3">Yes</button>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end w-100 pt-2">
              <ngb-pagination [collectionSize]="members.length" [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                [rotate]="true" [boundaryLinks]="false" (pageChange)="refreshMembers()">
                <ng-template ngbPaginationPrevious>
                  <span>&lsaquo;</span>
                </ng-template>
                <ng-template ngbPaginationNext>
                  <span>&rsaquo;</span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #loading>
      <div class="mt-5 h-100 w-100 flex align-center align-middle">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #changeRoleDialog let-data>
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
      Change member role
    </p>
    <mat-icon class="close-btn" (click)="changeRoleDialogRef.close()">close</mat-icon>
  </div>
  <div mat-dialog-content class="mb-2 align-items-center popup-body">
    <div class="form-field">
      <label for="input-role">Role</label>
      <br>
      <mat-form-field appearance="fill" id="input-role" style="padding: 0; margin-bottom: 16px;">
        <mat-select (selectionChange)="toggleControl($event)" [(ngModel)]="selectedRole">
          <mat-option [value]="'editor'">Editor</mat-option>
          <mat-option [value]="'reviewer'">Reviewer</mat-option>
          <mat-option [value]="'approver'">Approver</mat-option>
          <mat-option [value]="'viewer'">Viewer</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-checkbox [disabled]="selectedRole == 'editor' || selectedRole == 'viewer'" [(ngModel)]="readOnly"
        class="mr-4 mt-4">Read only
      </mat-checkbox>
    </div>
    <div class="form-field">
      <mat-checkbox [disabled]="selectedRole != 'editor' " [(ngModel)]="dailyReport" class="mr-4 mt-4">Daily Report
      </mat-checkbox>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="changeRole(data.member)" class="ml-2" color="primary" mat-raised-button>
      Save
    </button> <button class="ml-2" mat-button (click)="changeRoleDialogRef.close()">Cancel</button>
  </div>

</ng-template>