
<div class="popup-header d-flex">
    <p class="w-100">
      Daily Reports
    </p>
    <button matTooltip="Create" (click)="newReport()" mat-raised-button  color="primary" class="dark">
        New Report
    </button>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>
  
  <div mat-dialog-content>
    <table  matSort class="table w-100">
        <thead>
          <tr>
            <th scope="col" class="text-uppercase table-header">Report Name</th>
            <th scope="col" class="text-uppercase table-header" >Some info of reports 
      
            </th>
            <th scope="col" class="text-uppercase table-header" >Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of dailyReports">
            <td>
             {{report.name}}
            </td>
            <td>
              {{block.availability}}
            </td>
            <td>
              <div (click)="editBlock(block)" matTooltip="Edit" matTooltipClass="reports-tooltip" class="icon-edit" style="cursor: pointer;">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2761 2.85156L15.3776 0.953125C14.4636 0.0390625 12.9518 0.0390625 12.0378 0.953125L0.858094 12.0625L0.0143443 17.3008C-0.0911244 17.8633 0.401063 18.3555 0.963563 18.25L6.20184 17.4062L17.3112 6.22656C18.2253 5.3125 18.2253 3.80078 17.2761 2.85156ZM4.23309 10.8672L10.2097 4.92578L13.3386 8.05469L7.39716 14.0312V12.5547H5.70966V10.8672H4.23309ZM2.89716 16.3867L1.87763 15.3672L2.33466 12.8008L2.96747 12.1328H4.44403V13.8203H6.13153V15.2969L5.46356 15.9297L2.89716 16.3867ZM16.2214 5.13672L14.4987 6.85938L11.405 3.76562L13.1276 2.04297C13.444 1.72656 13.9714 1.72656 14.2878 2.04297L16.1862 3.94141C16.5378 4.29297 16.5378 4.82031 16.2214 5.13672Z"
                    fill="#219653" />
                </svg>
              </div>
              <mat-icon (click)="deleteBlock(block)" matTooltip="Delete" matTooltipClass="reports-tooltip" class="mat-icon_size-18"
                 style="cursor: pointer" svgIcon="trash"></mat-icon>

            </td>
          </tr>
        
        </tbody>
      </table>
  </div>

 