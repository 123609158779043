import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { UiService } from '../services/ui.service';
import { DailyReportsInitialsComponent } from './daily-reports-initials/daily-reports-initials.component';
@Component({
  selector: 'app-daily-reports',
  templateUrl: './daily-reports.component.html',
  styleUrls: ['./daily-reports.component.scss']
})
export class DailyReportsComponent implements OnInit {
  dailyReports=[];
  constructor(
    public backend: BackendService,
    public uiService: UiService,
    private dialog : MatDialog,
    public dialogRef: MatDialogRef<DailyReportsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

  }


  newReport(){
    const data = {
      assetId: this.data.assetId,
      isAssetOwner: this.data.isAssetOwner,
      asset: this.uiService.asset,
      projectId: this.uiService.project?.id
    };
  this.dialog.open(DailyReportsInitialsComponent, {
      width: '100%',
      height: '100%',
      data,
    });

  }

  onSubmit(){
    
  }
}


