import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from './../services/backend.service';
import { UiService } from './../services/ui.service';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  AfterContentChecked,
  ViewChild,
} from '@angular/core';
import { Project } from '../projects/item/project.type';
import { MatDialog } from '@angular/material/dialog';
import { MemberAddDialogComponent } from '../member-add-dialog/member-add-dialog.component';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject, Subscription } from 'rxjs';
import { ProjectAddFolderComponent } from '../project/add-folder/add-folder.component';
import { map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IImage } from '../models/image.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralService } from './../services/general.service';
import { IGroup } from '../models/group.interface';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ToastrService } from 'ngx-toastr';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { environment } from 'src/environments/environment';
import { FilterComponent } from '../project/filter/filter.component';
import { AddEditScopeComponent } from '../add-edit-scope/add-edit-scope.component';
import { AlignModelsComponent } from '../align-models/align-models.component';
import { AngularFireStorage } from '@angular/fire/storage';
import * as jsZip from 'jsZip';
import { v4 as uuidv4 } from 'uuid';
import { Status, TilesMessage, ThermalMessage, GENERAL, InspectionType } from './../models/app.enum';
import * as moment from 'moment';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { ColorPlateComponent } from './color-plate/color-plate.component';
import { LabelType, Options } from "@angular-slider/ngx-slider";
declare var uppy;
import * as XLSX from 'xlsx';
import { SeverityDetailComponent } from '../shared/severity-detail/severity-detail.component';
import { DailyReportsComponent } from '../daily-reports/daily-reports.component';
import { DailyReportsInitialsComponent } from '../daily-reports/daily-reports-initials/daily-reports-initials.component';
const timeFrameDifference = 0.95;  // In seconds
const logFrameDifference = 2; // In seconds
@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterViewInit, AfterContentChecked {

  @ViewChild('errorUploadDialog') errorUploadDialog: any;
  @ViewChild('engineDialog') engineDialog: any;
  @ViewChild('versionDiaLog') versionDiaLog: any;
  @ViewChild('projectModel') projectModelDialog: any;
  @ViewChild('project2DModel') project2DModelDialog: any;
  @ViewChild('exifModel') exifModelDialog: any;
  @ViewChild('3dModel') threeDModelDialog: any;
  @ViewChild('container') container: any;
  @ViewChild('logModelDialog') logModelDialog: any;
  @ViewChild('hotspotDetectionDialog') hotspotDetectionDialog: any;
  hotspotDetectionDialogRef;
  threeDModelDialogRef;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild(SelectContainerComponent) selectContainer: SelectContainerComponent;
  public projects$: Observable<Project[]>;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  @ViewChild('scrollBar', { read: InfiniteScrollDirective }) scrollBar: InfiniteScrollDirective;
  errorUploadDialogRef;
  engineDialogRef;
  videoDialogRef;
  versionDiaLogRef;
  projectModelRef;
  logModelRef;
  project2DModelRef;
  renameDialogSubcription = new Subscription();
  errorImages = [];

  onDestroy$ = new Subject();

  selectedImages: IImage[];

  selectedGroupId: string;
  groups: IGroup[];
  versions = [];
  isLoading: boolean = false;
  option = 'pictures';
  isColorImage = true;
  public idToken: string = '';
  public contextMenuPosition = { x: '0px', y: '0px' };
  reportType: string = 'upload';
  standardDeviation: any = 0;
  minDeviation: number;
  maxDeviation: number;
  options: Options = {
    floor: -100,
    hideLimitLabels: true,

    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        default:
          if (label == 0) {
            return `<span style="font-size:12px;">
            <span style="color:blue;    top: 5px;position: relative;" class="material-icons">device_thermostat</span>
            ${value + " °C"} </span>`;
          } else {
            return `<span style="font-size:12px;">
            <span  style="color:red;    top: 5px;position: relative; " class="material-icons">device_thermostat</span>
            ${value + " °C"} </span>`;
          }

        // 
      }
    }
  };
  public set activeFolder(value) {
    this.uiService.activeFolder = value;

    //this.uiService.activeFolderChange.next(value);
    if (value && value.data) {
      this.uiService.activeFolderPath = value.data.path;
    }
  }

  public get activeFolder(): any {
    return this.uiService.activeFolder;
  }

  public get project(): Project {
    return this.uiService.project;
  }

  public get projectId(): string {
    return this.uiService.selectedProjectId;
  }

  public get assetId(): string {
    return this.uiService.project?.assetId;
  }

  public get tree(): any {
    return this.uiService.tree;
  }

  public get nodes(): any {
    return this.uiService.nodes;
  }

  public set nodes(value) {

    this.uiService.nodes = value;
  }

  public get roles(): any {
    return this.uiService.roles;
  }

  public get members(): any {
    return this.uiService.members;
  }

  public get tags(): any {
    return this.uiService.tags;
  }

  public set images(images) {
    this.uiService.images = images;
  }

  public get images(): any[] {
    return this.uiService.images;
  }

  public set videos(videos) {
    this.uiService.videos = videos;
  }

  public get videos(): any[] {
    return this.uiService.videos;
  }

  public set checkedImages(images) {
    this.uiService.checkedImages = images;
  }

  public get checkedImages(): any[] {
    return this.uiService.checkedImages;
  }

  public set filteredImages(images) {
    this.uiService.filteredImages = images;
  }

  public get filteredImages(): any[] {
    return this.uiService.filteredImages;
  }

  public set movedImages(images) {
    this.uiService.movedImages = images;
  }

  public get movedImages(): IImage[] {
    return this.uiService.movedImages;
  }

  public set movedInfo(value: { projectId: string, folderPath: string }) {
    this.uiService.movedInfo = value;
  }

  public get movedInfo(): { projectId: string, folderPath: string } {
    return this.uiService.movedInfo;
  }

  public set selectedTag(value) {
    this.uiService.selectedTag = value;
  }

  public get selectedTag(): string {
    return this.uiService.selectedTag;
  }

  public set sensitiveTag(tag) {
    this.uiService.sensitiveTag = tag;
  }

  public get sensitiveTag() {
    return this.uiService.sensitiveTag;
  }


  public set selectedGroup(value) {
    this.uiService.selectedGroup = value;
  }

  public get selectedGroup(): string {
    return this.uiService.selectedGroup;
  }

  public get selectedTab(): string {
    return this.uiService.selectedTab;
  }

  public get activeFolderPath(): string {
    return this.uiService.activeFolderPath || '';
  }

  public set activeFolderPath(value) {
    this.uiService.activeFolderPath = value;
  }

  public get currentUploadFolderPath(): string {
    return this.uiService.currentUploadFolderPath
  }

  public get groups$() {
    return this.uiService.groups$;
  }

  public get isUploadImages(): boolean {
    return this.uiService.isUploadImages;
  }

  public get aiTags() {
    return this.uiService.projectAITags;
  }

  public get skipImages() {
    return this.uiService.skipImages;
  }

  public get success() {
    return Status.SUCCESS;
  }
  public get failed() {
    return Status.FAILED;
  }
  public get process() {
    return Status.PROCESSING;
  }
  public get queued() {
    return Status.QUEUED;
  }

  public get processMessage() {
    return TilesMessage.PROCESS;
  }
  public get failedMessage() {
    return TilesMessage.FAILED;
  }
  public get uploadFailedMessage() {
    return TilesMessage.UPLOAD_FAILED;
  }

  public get uploadMessage() {
    return TilesMessage.LOADING;
  }

  public get FORSSEA() {
    return InspectionType.FORSSEA;
  }
  appInferenceEngineMode;
  constructor(
    public backendService: BackendService,
    public uiService: UiService,
    public cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private snackBar: MatSnackBar,
    public generalService: GeneralService,
    private toast: ToastrService,
    private storage: AngularFireStorage,
    private confirmDialog: WaMatConfirmDialog
  ) {
    this.activeFolder = this.activeFolder;
    this.appInferenceEngineMode = environment.engineMode;
  }

  ngOnInit(): void {
    this.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.groups = groups;
    });
    this.backendService.getVersion().pipe(takeUntil(this.onDestroy$)).subscribe(versions => {
      this.versions = versions;
    });

    this.backendService.currentUser.getIdToken().then((idToken) => {
      this.idToken = idToken;
    }).catch(function (error) {
      // Handle error
    });

  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
  }

  ngAfterContentChecked(): void {
    if (this.uiService.uploadImagesCount !== 0
      && this.filterArrayByField(this.filteredImages, 'id').length === this.uiService.uploadImagesCount) {
      setTimeout(() => this.uiService.uploadImagesCount = 0, 2000);

    }
  }

  onScroll() {
    this.uiService.imagesContainerScrollEvent$.next();
  }

  selectProjectRoot(event): void {
    if (this.activeFolder) {
      this.activeFolder.setIsActive(false);
    }
    this.activeFolder = null;
    this.cdRef.markForCheck();
    event.stopImmediatePropagation();
  }

  getBreadcrumbs(): string[] {
    return this.activeFolder?.data.breadcrumb.name.split('/');
  }

  goToBreadcrumb(index: number): void {
    const breadcrumbs = this.getBreadcrumbs();
    if (this.tree) {
      let doc = this.tree.treeModel.nodes.find(
        (node) => node.name === breadcrumbs[0]
      );
      for (let i = 1; i <= index; i++) {
        doc = doc.children.find((child) => child.name === breadcrumbs[i]);
      }
      this.activeFolder = this.tree.treeModel.getNodeById(doc.id);
    }
  }

  openInviteDialog(): void {
    this.router.navigateByUrl(`/dashboard/members`);
    /*const isValid = this.backendService.domainControlValid('invite');
    if (isValid) {
      this.dialog.open(MemberAddDialogComponent, {
        width: '40vw',
        height: '100vh',
        panelClass: 'top-mr-dialog',
        data: {
          members: this.members,
          roles: this.roles,
          projectId: this.projectId
        }
      });
    } else {
      if (this.backendService.DOMAIN_CONTROL) {
        this.toast.error(`Invited users limit (${this.backendService.DOMAIN_CONTROL.invitedUsers}) reached. For more users please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
    }
    */

  }

  toggleImageSelection(id): void {
    if (this.checkedImages.indexOf(id) !== -1) {
      this.checkedImages = this.checkedImages.filter((image) => image !== id);
    } else {
      this.checkedImages = [...this.checkedImages, id];
    }
  }

  isChecked(imageId: string): boolean {
    // this.bubble()
    return this.selectedImages && this.selectedImages.map(selectedImage => selectedImage.id).includes(imageId);
  }

  deleteFolderContent(): void {
    const selectedImages = this.selectedImages.map(selectedImage => selectedImage.id);
    if (selectedImages.length) {
      const obs = selectedImages.map((id) =>
        // this.backendService.deleteFolderImage(id)
        this.backendService.markFolderImageDeleted(id)
      );
      combineLatest(obs).subscribe((result) => {
        selectedImages.forEach((id) => {
          this.filteredImages = this.filteredImages.filter(
            (image) => image.id !== id
          );
          this.images = this.images.filter(
            (image) => image.id !== id
          );
          this.images = this.uiService.projectImageContexts.filter(
            (image) => image.id !== id
          );
          this.images = this.uiService.projectImages.filter(
            (image) => image.id !== id
          );
        });

        this.selectedImages = [];
      });
    }
  }

  selectGroup(id: string): void {
    this.selectedGroup = id;
    const selectedGroup = this.groups.find(group => group.id === id);
    this.filteredImages = selectedGroup && selectedGroup.images
      ? this.images.filter(image => selectedGroup.images && selectedGroup.images.includes(image.id))
      : [];
    this.uiService.selectedImagesIds$.next([]);
  }

  close() {
    this.tags.forEach((tag) => {
      tag.isOpen = false;
    });
    this.sensitiveTag = null;
  }

  handleAddFolder(): void {
    let doc;
    let folders = [];
    if (this.activeFolder) {
      doc = this.activeFolder.data;
      folders = doc.children.map((folder) => folder.name);
    } else {
      doc = { path: `projects/${this.projectId}` };
      folders = this.nodes.map((node) => node.name);
    }

    console.log('handleAddFolder', doc, this.activeFolder);
    const dialogRef = this.dialog.open(ProjectAddFolderComponent, {
      width: '510px',
      data: {
        doc,
        folders,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        if (this.activeFolder) {
          doc.children = [
            ...doc.children,
            {
              name: result.name,
              doc: { name: result.name },
              path: result.path,
              breadcrumb: {
                name: `${this.activeFolder.data.breadcrumb.name}/${result.name}`,
              },
              children: [],
            },
          ].sort((a, b) => a.name.localeCompare(b.name));
          this.nodes = [...this.nodes];
        } else {
          this.nodes = [
            ...this.nodes,
            {
              name: result.name,
              doc: { name: result.name },
              path: result.path,
              breadcrumb: {
                name: `${result.name}`,
              },
              children: [],
            },
          ].sort((a, b) => a.name.localeCompare(b.name));
        }

        if (
          this.activeFolder &&
          this.activeFolder.treeModel && this.activeFolder.treeModel.getFocusedNode() &&
          !this.activeFolder.treeModel.getFocusedNode().isExpanded
        ) {
          setTimeout(() => {
            this.activeFolder.treeModel.getFocusedNode().toggleExpanded();
          }, 200);
        }
      }
    });

  }

  filterArrayByField(array: any[], field: string): any[] {
    return array.filter(item => {
      if (item) {
        item[field]
      }
    }
    );
  }

  showFailedUploads(): void {
    this.uiService.uploadImagesCount = 0;
    if (this.errorImages.length > 0) {
      this.openErrorUploadDialog(this.errorImages);
      this.filteredImages = this.filteredImages.filter(item => item.id);
    }
  }

  openErrorUploadDialog(files): void {
    this.errorUploadDialogRef = this.dialog.open(this.errorUploadDialog, {
      data: { images: files }
    });
  }

  itemDeselected() {
    if (!this.movedImages.length) {
      this.cancelMoving();
    }
  }
  itemSelected(event) { }

  openContextMenu(event) {
    if ((this.selectedImages && this.selectedImages.length) || this.movedImages.length) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.openMenu();
    }
  }

  selectMovedImages() {
    this.selectedImages.forEach(image => {
      this.movedImages.push(image);
    })
    this.snackBar.open(`${this.movedImages.length} Files Flagged for Move. Open target folder and right click to complete the move.`, 'Ok', {
      panelClass: 'snack-bar'
    });
  }

  cancelMoving() {
    this.movedImages = this.selectedImages = [];
    this.snackBar.dismiss();
  }

  isMovedImage(image: IImage): boolean {
    return this.movedImages.length && this.movedImages.map(image => image.id).includes(image.id);
  }

  pasteImages() {
    this.movedInfo = { projectId: this.projectId, folderPath: this.activeFolderPath };
    from(this.movedImages).pipe(switchMap(image => {
      return this.backendService.changeFolderForImage(image.id, this.movedInfo.projectId, this.movedInfo.folderPath);
    })).subscribe(() => {
      this.snackBar.dismiss();
      this.movedInfo = null;
      this.movedImages = []
    });
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  setSelectedGroupName($event) {
    if (this.selectedGroupId === null) {
      return;
    }
    this.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.groups = groups;
      if (this.groups.length == 0) {
        this.selectedGroupId = null;
        return;
      }
      const selectedGroup = this.groups.find(group => group.id === this.selectedGroupId);
      const imageIds = this.selectedImages.map(image => image.id);
      this.backendService.addHistory(imageIds, this.projectId, `Image assigned to group  ${selectedGroup.groupName}`, "group", selectedGroup)
      this.backendService.updateImageGroup$(selectedGroup, imageIds, this.projectId)
        .pipe(takeUntil(this.onDestroy$)).subscribe(() => {
          this.selectedImages = [];
          this.selectedGroupId = null;
        });
    });

  }

  openUppy() {
    const isValid = this.backendService.domainControlValid('storage');
    if (isValid) {
      if (this.uiService.currentUploadingFolder && this.uiService.activeFolder.data.id != this.uiService.currentUploadingFolder.id) {
        this.uiService.imagesCompleted$.next();
      } else {
        uppy.setOptions({
          restrictions: { maxNumberOfFiles: 1500 - this.uiService.folderImages.length },
        })
        uppy.getPlugin('Dashboard').openModal()
      }

    } else {
      if (this.backendService.DOMAIN_CONTROL) {
        this.toast.error(`Storage  limit (${this.backendService.DOMAIN_CONTROL.storage}) reached. For more storage please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
      setTimeout(() => {
        uppy.getPlugin('Dashboard').closeModal()
      }, 500);

    }

  }

  isCurrentFileUploading(): boolean {
    if (this.isUploadImages && this.uiService.currentUploadingFolder && this.uiService.activeFolder.data.id != this.uiService.currentUploadingFolder.id) {
      return true;
    }
    return false;
  }

  openAIEngineModal() {
    let data = {
      tags: this.aiTags,
      mode: '',
      newVersion: false
    };
    if (this.aiTags.length) {
      this.isLoading = true;
      this.backendService.getSkippedImages(this.projectId).pipe(takeUntil(this.onDestroy$)).subscribe((images) => {
        this.isLoading = false;
        data['skipImages'] = this.uiService.skipImages = images;
        if (images.length === 0) {
          data.mode = 'merge';
        }
        this.engineDialogRef = this.dialog.open(this.engineDialog, {
          data: data
        });
      })
    } else {
      this.engineDialogRef = this.dialog.open(this.engineDialog, {
        data: data
      });
    }

  }

  getSmallNotes(aiTags) {
    return aiTags.map(o => o.tag).join(",");
  }

  engineStatus(): boolean {
    return this.generalService.getReadOnlyIfAiModalProcess();
  }

  /* radioChange(event, data) {
     const filter = this.versions.filter(o => o.modalType.toLowerCase() == event.value.toLowerCase()).sort((a, b) => a.version - b.version);
     const latestVersion = filter[filter.length - 1];
     data['modalName'] = latestVersion.modalName;
     data['version'] = latestVersion.version;
     data['modalType'] = latestVersion.modalType;
     data['allImages'] = true;
     data['versionUpdate'] = null;
 
     data['overlapImages'] = this.skipImages.filter(image => {
       return (image.polygons.some(o => (o.tag === latestVersion.modalType))
         || image.verifiedAnnotations
       )
     })
     if (this.uiService.engine.length > 0) {
       const processedModal = this.uiService.engine.filter(o => o.modal_type == latestVersion.modalType
         && o.modal_name == latestVersion.modalName).sort((a, b) => a.createdTime.toDate() - b.createdTime.toDate());
       if (processedModal.length > 0) {
         data['allImages'] = false;
         const lastProcessedModal = processedModal[processedModal.length - 1];
         if (lastProcessedModal.version != latestVersion.version) {
           data['versionUpdate'] = lastProcessedModal;
         }
       }
     }
   }
   */
  verifyPreviousRunEngine() {
    if (this.uiService.engine.filter(o => o.status == 'success').length > 0) {
      return true;
    }
    return false;
  }

  runEngine(data) {
    data.spinner = true;
    let newVersionModal = [];
    this.aiTags.forEach(tag => {
      const filter = this.versions.filter(o => o.modalType.toLowerCase() == tag.tag.toLowerCase()).sort((a, b) => a.version - b.version);
      const latestVersion = filter[filter.length - 1];
      data['allImages'] = true;
      if (this.uiService.engine.length > 0) {
        data['allImages'] = false;
        const processedModal = this.uiService.engine.filter(o => o.status == 'success' && o.modal_type == latestVersion.modalType
          && o.modal_name == latestVersion.modalName).sort((a, b) => a.createdTime.toDate() - b.createdTime.toDate());
        if (processedModal.length > 0) {
          const lastProcessedModal = processedModal[processedModal.length - 1];
          if (lastProcessedModal.version != latestVersion.version) {
            data['newVersion'] = true;
            data['allImages'] = true;
            data['lastVersion'] = lastProcessedModal;
            newVersionModal.push({
              version: latestVersion.version,
              modalType: latestVersion.modalType,
              modalName: latestVersion.modalName,
              lastVersion: lastProcessedModal,
              tag: tag
            })
          }
        }
      }

      const param = {
        allImages: data.allImages,
        version: latestVersion.version,
        modalType: latestVersion.modalType,
        userId: this.backendService.currentUser.uid,
        projectId: this.projectId,
        modalName: latestVersion.modalName,
        skipImages: data.mode == 'skip' ? this.skipImages.map(o => o.imageId).filter(this.generalService.onlyUnique) : [],
        mode: data.mode,
        newVersion: data.newVersion
      }
      this.backendService.runEngine(param).pipe(take(1)).subscribe();
    });

    setTimeout(() => {
      data.spinner = false;
      this.engineDialogRef.close();
      if (newVersionModal.length > 0) {
        this.versionDiaLogRef = this.dialog.open(this.versionDiaLog, {
          data: newVersionModal,
          disableClose: true
        });
      }
    }, 2000);
  }

  getTagName(data) {
    return data.map(o => o.tag).map(o => o.tag).join(",")
  }

  deleteAnnotations() {
    this.backendService.deleteAnnotations(this.projectId)
  }

  openVideoModal(video) {
    this.dialog.open(VideoModalComponent, {
      data: video,
      width: '100%',
      height: '100%',
      panelClass: 'video-dialog'
    });
  }

  choose(option) {
    this.option = option;
  }

  changeImageType() {
    this.isColorImage = !this.isColorImage;
    this.uiService.isColorImage = this.isColorImage;
  }

  openFilterPanel() {
    const dialogRef = this.dialog.open(FilterComponent, {
      width: '20vw',
      height: '100vh',
      panelClass: 'top-mr-dialog',
      data: {
        tags: this.tags.concat(this.uiService.project?.projectType === 'thermal' ?
          [
            {
              tag: 'Hotspots', 'isThermal': true,
            }, {
              tag: 'Coldspots', 'isThermal': true,
            }
          ]
          : []),
        projectId: this.projectId
      }
    });
    /* dialogRef.beforeClosed().pipe(takeUntil(this.onDestroy$)).pipe().
       subscribe(data => {
         if (data && data.group) {
           this.selectedTag = "groups";
           this.selectGroup(data.group.id);
           this.uiService.tagSelectedEvent$.next();
 
         }
       })
       */
  }

  changeProject(id) {
    this.uiService.selectedProjectId = id;
    this.uiService.changeProjectEvent$.next();
  }

  openAsset() {
    this.uiService.isAssetPanelExpand = !this.uiService.isAssetPanelExpand;
  }

  scope() {
    this.dialog.open(AddEditScopeComponent, {
      width: '50%',
      data: { project: this.uiService.project }
    });
  }
  getSeverity(value) {
    const tag = this.tags.find(o => o.tag === this.uiService.selectedTag);
    if (tag) {
      return tag.levels.find(o => o.level == value).title;
    }
    return "";
  }

  openCanvas(imageId) {
    this.router.navigateByUrl(`/dashboard/projects/${this.projectId}/images/${imageId}`);
  }

  getApproxTime() {
    if (this.uiService.project.executionTime &&
      this.uiService.project.approxModelExecutionTime) {
      const executionDate = moment(this.uiService.project.executionTime.toDate()).add(this.uiService.project.approxModelExecutionTime, 'hours');
      const serverTime = moment(this.backendService.getServerTime());
      var duration = moment.duration(executionDate.diff(serverTime));
      if (duration.asHours() >= 0)
        return `The process with take approximately <b> ${duration.asHours().toFixed(2)} hour</b>.`
      else
        return `The process is taking more than approx estimate time <b>${this.uiService.project.approxModelExecutionTime} hr.</b>.We will inform you once it's finished`
    }
  }


  generateModel() {
    if (this.uiService.project?.tileStatus === this.success) {
      // On 3D icon click directly move to model screen
      this.openModel();
      return;
    }
    if (this.getReadOnlyForCurrentUser()) {
      this.toast.warning("You don't have editor rights")
      return;
    }
    this.message = "";
    this.threeDModelDialogRef = this.dialog.open(this.threeDModelDialog, {
      panelClass: 'model-panel',
      data: {
        project: this.uiService.project,
        isBaselineModel: false,
        cameraLens: this.uiService.project.cameraLens || 'normal'
      }
    })
  }


  toggle3DIcon(image) {
    const exclude = this.uiService.excluded3DIamges.includes(image.id);
    this.backendService.exclude3DImage$(image.id, image.projectId, !exclude, this.uiService.excluded3DIamges.length).subscribe(() => { })

  }
  clearSelectedImages() {
    this.selectedImages = []
  }
  getExcudedImages(imageId) {
    return this.uiService.excluded3DIamges.includes(imageId)
  }

  isAssetOwner: boolean = false;
  verifyUserIsAssetOwner() {
    const owners = this.uiService.allProjects.filter(project => project.people[this.backendService.currentUser.uid].role == "owner");
    if (owners.length > 0 || this.uiService.asset?.createdBy == this.backendService.currentUser.uid) {
      this.isAssetOwner = true;
    }
  }

  openBaseLineModel() {
    this.verifyUserIsAssetOwner();
    const data = {
      assetId: this.assetId,
      projects: this.uiService.allProjects,
      isAssetOwner: this.isAssetOwner,
      asset: this.uiService.asset,
      baselineModel: true,
      selectedProjectId: this.uiService.project?.id
    };
    this.projectModelRef = this.dialog.open(this.projectModelDialog, {
      width: '100%',
      height: '100%',
      panelClass: 'report-panel',
      data,
    });

  }

  openModel() {
    if (this.isView3DModelBlock()) {
      return;
    }
    this.verifyUserIsAssetOwner();
    const data = {
      assetId: this.assetId,
      projects: this.uiService.allProjects,
      isAssetOwner: this.isAssetOwner,
      asset: this.uiService.asset,
      selectedProjectId: this.uiService.project?.id
    };
    this.projectModelRef = this.dialog.open(this.projectModelDialog, {
      width: '100%',
      height: '100%',
      panelClass: 'report-panel',
      data,
    });

  }

  open2DModel() {
    this.verifyUserIsAssetOwner();
    const data = {
      assetId: this.assetId,
      projects: this.uiService.allProjects,
      isAssetOwner: this.isAssetOwner,
      asset: this.uiService.asset,
      selectedProjectId: this.uiService.project?.id
    };
    this.project2DModelRef = this.dialog.open(this.project2DModelDialog, {
      width: '100%',
      height: '100vh',
      panelClass: 'report-panel',
      data,
    });
  }

  handleAdd() {
    document.getElementById('model_3d_image').click();

  }


  @ViewChild('progressBar') progressBar: any;
  @ViewChild('uploadProgressBar') uploadProgressBar: any;
  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  async fileChoose(event) {
    if (!event.target.files.length) { return };
    if (!this.backendService.DOMAIN_CONTROL?.features?.threeDModel) {
      this.toast.warning("Your domain does not have permission to use 3D access.")
      return;
    };
    const file = event.target.files[0];
    this.isImageUploading$.next(true);
    let isModel, isObjFile, isMtl;
    try {
      const zipFiles = await jsZip.loadAsync(file);
      Object.keys(zipFiles.files).forEach((file) => {
        if (file.toLocaleLowerCase().indexOf(".obj") != -1 ||
          // file.toLocaleLowerCase().indexOf(".3ds") != -1 ||
          // file.toLocaleLowerCase().indexOf(".ply") != -1 ||
          file.toLocaleLowerCase().indexOf(".fbx") != -1 ||
          file.toLocaleLowerCase().indexOf(".dae") != -1 ||
          //  file.toLocaleLowerCase().indexOf(".x3d") != -1 ||
          file.toLocaleLowerCase().indexOf(".gltf") != -1 ||
          file.toLocaleLowerCase().indexOf(".glb") != -1
          //  || file.toLocaleLowerCase().indexOf(".stl") != -1
        ) {
          if (file.toLocaleLowerCase().indexOf(".obj") != -1) {
            isObjFile = true;
          }
          isModel = true;
        }
        if (file.toLocaleLowerCase().indexOf(".mtl") != -1) {
          isMtl = true;
        }
      })
    } catch (ex) {
      this.toast.error(ex.toString());
      this.isImageUploading$.next(false);
      return;
    }
    if (isObjFile && !isMtl) {
      this.isImageUploading$.next(false);
      this.toast.warning("OBJ model required the material file.")
      return;
    }

    if (!isModel) {
      this.isImageUploading$.next(false);
      this.toast.warning("The Process required  valid supported file formats.")
      return;
    }
    const projectId = this.uiService.selectedProjectId;
    const ref = this.storage.ref('baseFiles').child(uuidv4());
    if (file) {
      var uploadTask = ref.put(file);
      const index = this.uiService.projectModel.findIndex(o => o.projectId == projectId);
      if (index == -1) {
        this.uiService.projectModel.push({
          projectId: this.uiService.selectedProjectId,
          uploadPercentage: 1,
          uploadTask: uploadTask
        })
      }
      uploadTask.task.on('state_changed', async (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.updatePercentage(progress);
      },
        (error) => {
          this.isImageUploading$.next(false);
          this.removeProcess(projectId);
        },
        () => {
          ref.getDownloadURL()
            .subscribe((url) => {
              this.isImageUploading$.next(false);

              this.backendService.updateProjectModelZip(projectId, url).then(() => {
                this.backendService.generate3DModel(projectId, true).pipe(take(1)).subscribe(response => {
                  // change status to request processing

                  this.removeProcess(projectId);
                  this.threeDModelDialogRef.close();
                }, error => {
                  this.removeProcess(projectId);
                  this.threeDModelDialogRef.close();
                  this.backendService.updateProjectTileStatus(projectId, "")
                })

              });
            })
        }
      );
    }
  }


  updatePercentage(percentage) {
    const index = this.uiService.projectModel.findIndex(o => o.projectId == this.uiService.selectedProjectId);
    if (index != -1) {
      this.uiService.projectModel[index].uploadPercentage = percentage;
    }
  }

  removeProcess(projectId) {
    const index = this.uiService.projectModel.findIndex(o => o.projectId == projectId);
    if (index != -1) {
      this.uiService.projectModel.splice(index, 1);
    }
  }

  getUploadState() {
    const state = this.uiService.projectModel.find(o => o.projectId == this.uiService.selectedProjectId);
    if ((state && state.uploadPercentage === 100 && this.uiService.project.tileStatus) || (state && state.uploadTask.task.state_ === 'success')) {
      this.removeProcess(this.uiService.project.id);
      return false;
    }
    if (state && state.uploadPercentage) {
      return true;
    }
    return false;
  }

  getProgressPercentage() {
    const state = this.uiService.projectModel.find(o => o.projectId == this.uiService.selectedProjectId);
    return state.uploadPercentage;
  }

  cancelUpload() {
    const index = this.uiService.projectModel.findIndex(o => o.projectId == this.uiService.selectedProjectId);
    if (index != -1) {
      this.uiService.projectModel[index].uploadTask.cancel();
    }
  }

  message: string = "";
  projectImageModel(data) {
    if (!this.backendService.DOMAIN_CONTROL?.features?.threeDModel) {
      this.toast.warning("Your domain does not have permission to use 3D access.")
      return;
    };
    if (this.uiService.projectInfo.images - this.uiService.excluded3DIamges.length < 20) {
      this.message = 'There appear to be very few images for 3D, it is highly likely the 3D model generation will fail or will give a poor output.';
      return;
    }
    this.callGenerateModel(data);
  }

  generateModelMinImages(data) {
    this.callGenerateModel(data);
  }

  callGenerateModel(data) {
    const isBaseLineModel = !this.uiService.model && data.isBaselineModel ? true : false;
    // refering this model as baseline model
    if (isBaseLineModel) {
      this.backendService.addBaselineModels$(this.assetId).subscribe();
    }
    this.message = "";
    this.backendService.updateProjectTileStatus(this.uiService.selectedProjectId, "requestProcessing") //instantly show loading
    this.backendService.generate3DModel(this.uiService.selectedProjectId,
      false, isBaseLineModel, data.cameraLens).pipe(take(1), share()).subscribe(response => {
      }, error => {
        this.project.tileStatus = '';
        this.backendService.updateProjectTileStatus(this.uiService.selectedProjectId, "")
      })
  }

  isMinProjects(): boolean {
    const projectCount = this.uiService.allProjects.filter(o => o.assetId == this.uiService.asset?.id).length;
    return projectCount < 2 ? true : false;
  }

  getToolTip() {
    if (this.isMinProjects()) {
      return 'Must have 2 or more inspections of asset for Time-series view';
    } else {
      return 'Timeline View'
    }
  }

  openTimeline() {
    if (!this.isMinProjects()) {
      this.router.navigateByUrl(`/dashboard/asset/${this.uiService.asset?.id}/project/${this.uiService.selectedProjectId}`);
    }
  }

  openDailyReport(){
    const data = {
      assetId: this.assetId,
      isAssetOwner: this.isAssetOwner,
      asset: this.uiService.asset,
      selectedProjectId: this.uiService.project?.id
    };
  this.dialog.open(DailyReportsComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'report-panel',
      data,
    });

  }



  handleCancel(data): void {
    this.confirmDialog
      .open(
        `Are you sure you want to cancel the process for "${this.uiService.project.name}"?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          data.isCancelling = true;
          const subs = this.backendService.cancel3DModel(this.uiService.selectedProjectId, 'projects').pipe(take(1), share()).subscribe(() => {
            subs.unsubscribe();
            data.isCancelling = false;
            if (this.uiService.project.isBaseLineModel && this.uiService.model && this.uiService.model.tileStatus == 'baseline') {
              this.backendService.updateBaselineStatus$(this.uiService.model.id).subscribe();
            }
            this.threeDModelDialogRef.close();
          });
        }
      });
  }

  getToolTipText() {
    if (this.getReadOnlyForCurrentUser()) {
      return GENERAL.access;
    }
    if (this.project?.tileStatus === this.process || this.project?.tileStatus === this.queued) {
      return TilesMessage.PROCESS;
    }

    return `3D model`
  }

  showBaselineModel() {
    if (this.project?.tileStatus && this.project?.tileStatus === this.success && this.project?.isAligned) {
      return false;
    }

    if (this.uiService.model && this.uiService.model.tileStatus === this.success) {
      return true;
    }


  }

  isView3DModelBlock() {
    if (!this.project?.tileStatus || this.project?.tileStatus != this.success) {
      return true;
    }
    return false;
  }

  viewModelToolTips() {
    if (!this.project?.tileStatus) {
      return TilesMessage.GENERATE_MODEL_VIEW;
    }
    if (this.project?.tileStatus === this.process || this.project?.tileStatus === this.queued) {
      return TilesMessage.PROCESS;
    }
    if (this.project?.tileStatus === this.failed) {
      return TilesMessage.FAILED;
    }

    return `View your model`;
  }

  getFailedMessage() {
    return !this.project?.modelGenerateFailed ? this.uploadFailedMessage : this.failedMessage;
  }

  isEmptyObject(obj) {
    if (typeof obj === 'object' && obj != null && Object.keys(obj).length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  openExifInfo(metaData) {
    if (!this.isEmptyObject(metaData)) {
      this.dialog.open(this.exifModelDialog, {
        panelClass: 'report-panel',
        data: {
          data: metaData,
          isExapnd: false,
          more: Object.entries(metaData),
        },

      });
    }
  }

  openPrompt(data, isMore) {
    if (isMore) {
      data.isExapnd = true;
    } else {
      data.isExapnd = false;
    }
  }

  checkTopSeverity(image) {
    const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
    if (levels && levels.length) {
      const maxLevel = Math.max(...levels);
      const tag = image.tags.find(element => element.sensitive === maxLevel);
      const findTag = this.uiService.tags.find(x => x.tag == tag.tag && x.status == 'active')
      if (findTag)
        return findTag.levels?.find(o => o.level == maxLevel)?.color;
    }
    return "";
  }

  getSeverityDetail(image) {
    return this.generalService.getSeverityDetail(image,this.uiService.tags);
  }

  thermalLoading: boolean = false;
  openColorPalette() {
    const dialogRef = this.dialog.open(ColorPlateComponent, {
      width: '500px'
    })
    dialogRef.afterClosed().subscribe(r => {
      if (r && r.colorPalette) {
        this.thermalLoading = true;
        this.backendService.convertColorPalette(this.projectId, r.colorPalette).subscribe((response) => {
          this.thermalLoading = false;
        }, error => {
          this.thermalLoading = false;
        })
      }
    });
  }

  viewColorPaletteToolTips() {
    if (!this.project.thermalColorConversionStatus || this.project.thermalColorConversionStatus === this.success) {
      return 'Change color palette';
    }
    if (this.project.thermalColorConversionStatus == this.process || this.project.thermalColorConversionStatus == this.queued) {
      return ThermalMessage.PROCESS;
    }
    if (this.project.thermalColorConversionStatus == this.failed) {
      return ThermalMessage.FAILED;
    }
  }

  openAnomalyPrompt() {
    const dialogRef = this.dialog.open(ColorPlateComponent, {
      width: '400px',
      height: '80vh'
    })
  }


  startHotSpotDetection() {
    this.standardDeviation = this.uiService.project.std_dev;
    this.minDeviation = this.standardDeviation - 10;
    this.maxDeviation = this.standardDeviation + 10;
    this.hotspotDetectionDialogRef = this.dialog.open(this.hotspotDetectionDialog, {
      width: '500px',
      data: {
        standardDeviation: parseInt(this.standardDeviation) || 0,
        minTemp: this.uiService.project?.minTemperature || 10,
        maxTemp: this.uiService.project?.maxTemperature || 25,
      },
      scrollStrategy: null
    });
  }

  add(data) {
    data.standardDeviation = data.standardDeviation + 1;
  }

  subtract(data) {
    data.standardDeviation = data.standardDeviation - 1;
  }

  hotspotLoading: boolean = false;
  hotspotDetect(data) {
    this.hotspotLoading = true;
    this.hotspotDetectionDialogRef.close();
    this.backendService.anomalyDetection(this.projectId, data.minTemp, data.maxTemp, data.standardDeviation).subscribe((response) => {
      this.hotspotLoading = false;
    }, error => {
      this.hotspotLoading = false;
    })
  }


  public isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLinking$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inspectionFileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    this.isProcessing$.next(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = reader.result;
      // const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
      // Specify the range of cells for headers (A10 to G10)
      const headerRange = XLSX.utils.decode_range('A10:G10');
      const headers = [];

      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        const header = workbook.Sheets[sheetName][cellAddress]
          ? workbook.Sheets[sheetName][cellAddress].v
          : undefined;
        headers.push(header);
      }

      // Specify the range of cells for data (starting from A11 to the last row)
      const dataRange = XLSX.utils.decode_range(
        'A11:' +
        XLSX.utils.encode_col(
          XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.c
        ) +
        XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.r
      );
      let dataArray = [];


      for (let row = dataRange.s.r; row <= dataRange.e.r; row++) {
        const rowData = {};

        for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cellValue = workbook.Sheets[sheetName][cellAddress]
            ? workbook.Sheets[sheetName][cellAddress].v
            : undefined;
          const header = headers[col - dataRange.s.c];
          rowData[header] = cellValue;
        }

        dataArray.push(rowData);

      }
      if (!headers.includes('Session') || !headers.includes('Video Time')) {
        this.isProcessing$.next(false);
        this.toast.warning("Sheet does not container Sessions and Video Time");
        return;
      }
      const params = [];
      this.uiService.videos.forEach(video => {
        const data = this.filterRecordsBetweenOccurrences(dataArray, this.getChannelFileName(video.fileName));
        const filter = data.filter(o => o.Session && (o.Session.includes('.png') || o.Session.includes('.jpg')))
        const images = this.uiService.projectImageContexts.filter(image =>
          filter.some(data => this.getChannelFileName(image['fileName']) === data['Session'])
        );
        images.forEach(image => {
          const findFrame = filter.find(o => o.Session === this.getChannelFileName(image['fileName']));
          const excelDate = new Date(findFrame.Time)
          const matchedLogs = this.uiService.logs.find(data => {
            // Calculate the time difference using Moment.js
            const diffSec = moment(excelDate).diff(moment(data.LogTime), 'second');
            // Check if the time difference is within the specified range (2 hours)
            return (diffSec <= 7200 && diffSec >= (7200 - logFrameDifference));
          });
          params.push({
            imageId: image.id,
            videoId: video.id,
            logFrame: matchedLogs ? matchedLogs : null,
            frame: this.timeStringToSeconds(findFrame['Video Time'])
          })
        });

      });

      if (params.length) {
        const logFilters = params.filter(o => o.logFrame);
        let index = 0;
        const annotations = [];
        logFilters.forEach(log => {
          if (log.logFrame.POSITI) {
            const coordinatesString = log.logFrame.POSITI;

            // Use regular expression to match the first decimal value
            const match = log.logFrame.DEPIN.match(/\d+\.\d+/);
            // Extract the first decimal value from the match
            const altitude = match ? match[0] : 0;

            const hrp = log.logFrame.STDHRP.split(',');

            // Split the string by comma to separate latitude, longitude, and other values
            const parts = coordinatesString.split(',');
            // Extract latitude and longitude
            const latitude = parseFloat(parts[0]);
            let longitude = parseFloat(parts[1]);
            if (longitude > 180) {
              longitude = -(360 - longitude);
            }
            index++;
            annotations.push({
              title: "Label " + index,
              latitude: latitude,
              id: uuidv4(),
              longitude: longitude,
              altitude: parseFloat(altitude),
              heading: parseFloat(hrp[0]),
              roll: parseFloat(hrp[1]),
              pitch: parseFloat(hrp[2]),
              imageId: log.imageId,
              videoId: log.videoId,
              color: this.generalService.generateRandomColor()
            })
          }
        });
        if (annotations.length && this.uiService.selectedBaseModel === '2d') {
          const subs = this.backendService
            .get2DModels$(this.uiService.project?.assetId).pipe(
              takeUntil(this.onDestroy$)
            ).subscribe((techDrawings: any) => {
              subs.unsubscribe();
              if (techDrawings.length && techDrawings[0].isKMLFile) {
                this.isLinking$.next(true);
                let i = 0;
                this.backendService.createTechnicalDrawingLabels(techDrawings[0], annotations).subscribe((result) => {
                  this.toast.success("Labels created successfully;");
                  this.backendService.createLinkingIfNotExist(this.assetId, techDrawings[0].id, this.projectId).subscribe((sub) => {
                    annotations.forEach(label => {
                      this.backendService.linkImages(this.assetId, techDrawings[0].id, label.id, [label.imageId], "images", this.projectId, "2d").subscribe((res) => {
                        i++;
                        if (i === annotations.length) {
                          this.isLinking$.next(false);
                          this.toast.success("images linked successfully;");
                          this.logModelRef.close();
                        }
                      })
                      this.backendService.linkImages(this.assetId, techDrawings[0].id, label.id, [label.videoId], "videos", this.projectId, "2d").subscribe();
                    });
                  })
                })
              }
              else {
                this.logModelRef.close();
              }
            })
        }
        if (annotations.length && this.uiService.selectedBaseModel === '3d') {
          const subs = this.backendService
            .get3DModels$(this.uiService.project?.assetId).pipe(
              takeUntil(this.onDestroy$)
            ).subscribe((model: any) => {
              subs.unsubscribe();
              if (model) {
                this.isLinking$.next(true);
                let i = 0;
                this.backendService.create3DModelLabels(model.id, annotations).subscribe(() => {
                  this.toast.success("Labels created successfully;");
                  this.backendService.createLinkingIfNotExist(this.assetId, model.id, this.projectId).subscribe((sub) => {
                    annotations.forEach(label => {
                      this.backendService.linkImages(this.assetId, model.id, label.id, [label.imageId], "images", this.projectId, "3d").subscribe((res) => {
                        i++;
                        if (i === annotations.length) {
                          this.isLinking$.next(false);
                          this.toast.success("images linked successfully;");
                          this.logModelRef.close();
                        }
                      })
                      this.backendService.linkImages(this.assetId, model.id, label.id, [label.videoId], "videos", this.projectId, "3d").subscribe();
                    });
                  })
                })


              }
              else {
                this.logModelRef.close();
              }
            })
        }
        this.backendService.addImagesToVideos(params).pipe().subscribe(response => {
          this.isProcessing$.next(false);
        }, error => {
          this.isProcessing$.next(false);
          throw (error);
        })
      }
    };
    reader.readAsArrayBuffer(file);
    const ref = this.storage.ref('inspection-logs').child(uuidv4() + file.name);
    if (file) {
      var uploadTask = ref.put(file);
      uploadTask.task.on('state_changed', async (snapshot) => { },
        (error) => {
          this.isProcessing$.next(false);
        },
        () => {
          ref
            .getDownloadURL()
            .subscribe((url) => {
              this.isProcessing$.next(false);
              this.backendService.addLogExcel(this.projectId, url).then(() => { });
              // this.toast.success("File uploaded succesfully");

            })
        }
      );
    }


  }


  getChannelFileName(fileName) {
    return fileName.replace(/_Ch1[^.]*\./, '.').replace(/_Ch2[^.]*\./, '.')
      .replace(/_Ch3[^.]*\./, '.');
  }

  filterRecordsBetweenOccurrences(records, targetName) {
    let startIndex = -1;
    let endIndex = -1;

    for (let i = 0; i < records.length; i++) {
      if (records[i]['Session'] === targetName) {
        if (startIndex === -1) {
          startIndex = i;
        }
        endIndex = i;
      }
    }

    if (startIndex !== -1 && endIndex !== -1) {
      return records.slice(startIndex, endIndex + 1);
    } else {
      return [];
    }
  }
  timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + (seconds + timeFrameDifference);
  }

  public isLogUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  addLogFiles() {
    document.getElementById('add_logs').click();

  }
  logUploadPercentage = 0;
  async fileLogChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    try {
      const projectId = this.uiService.selectedProjectId;
      const ref = this.storage.ref('logFiles').child(uuidv4() + ".zip");
      if (file) {
        var uploadTask = ref.put(file);
        this.isLogUploading$.next(true);
        uploadTask.task.on('state_changed', async (snapshot) => {
          this.logUploadPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
          (error) => {
            this.logUploadPercentage = 0;
            this.isLogUploading$.next(false);
          },
          () => {
            ref.getDownloadURL()
              .subscribe((url) => {
                this.logUploadPercentage = 0;
                this.backendService.uploadLogs(projectId, url).pipe(take(1)).subscribe(response => {
                  this.isLogUploading$.next(false);
                  this.toast.success('Logs added sucessfully');
                }, error => {
                  this.isLogUploading$.next(false);
                  this.toast.warning("Something issue with logs processing");
                })
              })
          }
        );
      }
    } catch (ex) { }
  }

  inspectionLog() {
    document.getElementById('inspection_file').click();
  }

  openLogPrompt() {
    this.logModelRef = this.dialog.open(this.logModelDialog, {
      width: '500px',
      height: '500px',
      panelClass: 'report-panel'
    });

  }

  clearFilter(){
    this.selectedTag = 'All';
    this.uiService.tagSelectedEvent$.next();
  }

}