<div *ngIf="(images$ | async).loading" class="w-100 h-100 d-flex align-items-center justify-content-center">
  <span>
    <mat-spinner [diameter]="50"></mat-spinner>
  </span>
</div>

<div class="grid-contaner full h-100 w-100" *ngIf="(images$ | async).value as imagesList">
  <div class="grid-y h-100 w-100 " #container>
    <div class="w-100">
      <app-image #appImage [isImageSessionLoading]="isImageSessionLoading" [isAIDetection]="isAIDetection"
      [parentImgId] ="parentImgId"  [isAnnotationVerified]="isAnnotationVerified" [imageName]="fileName" [selectedImage]="imageId"
        [imageId]="imageId" [taggingMode]="taggingMode" [annotationId]="imageAnnotations?.id" [groups$]="groups$"
        (maskChange)="maskChange()" (sliderChange)="handleSliderChange($event)" (zoomChange)='handleZoomIn($event)'
        (drawingChange)='drawingChangeEventHandler()' (saveZoomImage)="saveZoomHandler()"
        (tagFilter)="tagFilter($event)" [stage] ="stage"></app-image>

      <div class=" mt-1 mb-1 d-flex note">
        <div *ngIf=" ((relationsWithBaseImagesCount$ | async) > 0)" class="d-flex">
          <p class="context-link-text">See <a class="context-link" (click)="appImage.manageTitles('child-image')">Show
              Contexts</a> for Linked base image</p>
        </div>
        <div class="d-flex w-70">
          <!-- <span style="font-size: 1rem; cursor: pointer;" *ngIf="this.parentImageId && !zoomedImages.length" (click)="goToParentImage()" class="material-icons">arrow_back</span> -->
          <mat-icon  matTooltip ="Goto parent image" (click)="goToParentImage(parentImgId)" *ngIf ="parentImgId" > arrow_back</mat-icon>
          <img matTooltip ="Zoomed image"  *ngIf ="parentImgId" style="height:25px" src="assets/zoomed.jpg" >
          <div   style="height: 40px;margin: 1px;padding: 2px;border: 1px solid #cbc8c8;" *ngFor="let zoom of zoomedImages; let i = index">
            <p (click)="setZoomed(zoom,$event)" matTooltip="Click to select zoomed image" class="zommed-point mr-1" >
            <mat-icon  id="delete" class="close-icon">close</mat-icon> <!-- Small cross icon -->
            </p>
          </div>

          <p class="box">
            <span *ngIf="filterTag != 'All'"
              style="color: #28a745;font-size: 12px; margin-left: 10px;margin-top: 2px;">{{filterTag}}</span>
            <button class="ml-auto" *ngIf="filteredImages.length && taggedAreas.get(imageId)"
              (click)="openHistoryBottomSheet()">
              <mat-icon>history</mat-icon>
              History
            </button>
            <button *ngIf="filteredImages.length && taggedAreas.get(imageId)" (click)="openNarrationBottomSheet()">
              <mat-icon>notes</mat-icon>
              {{getName()}}
              <small class="small-note" *ngIf="narrationModifiedBy">
                (Previously edited by {{narrationModifiedBy}})
              </small>
            </button>


          </p>
          <div *ngIf="imagesList.length != 0" class="image-number">
            {{filteredImages.length == 0 ? '0' : getSelectedImageIndex() }} of {{filteredImages.length}}

          </div>
        </div>

      </div>
    </div>

    <div class="justify-content-center w-100" style="display: flex; flex-grow: 1;">
      <!-- <ng-container *ngIf="images && images.loading">
        <div style="padding: 20px;">Loading images...</div>
      </ng-container> -->

      <ng-container *ngIf="!imagesList.length">
        <div class="d-flex align-items-center" style="padding: 0 20px 40px;">
          No images in folder
        </div>
      </ng-container>

      <!-- <ng-container *ngIf="(images && !images.loading) && imagesList.length > 0"> -->
      <div *ngIf="imagesList.length != 0" [ngClass]="{ 'd-flex': !isVerticalScroll}"
        class="justify-content-center carousel-wrap w-100">
        <div class="carousel mt-0 mb-0 w-100" style="min-height: 200px;">
          <div class="carousel__btn -left">
            <button mat-icon-button (click)="activateImage(-1)">
              <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
            </button>
          </div>

          <div class="carousel__main" (keyup.escape)="removeActiveAreaTag()" tabindex="0">
            <div class="carousel__main-container" style="position: relative;overflow: hidden;">
              <!-- context child images -->
              <div class="child-image-wrapper" *ngFor="let area of getContext(); let i = index"
                [style]="getChildImageStyle(area)">
                <ng-container
                  *ngIf="area && area.annotation && area.tag === 'context' && childContextImages.get(area.annotation?.id)?.isShowed">
                  <div class="image-wrapper">
                    <img [src]="childContextImages.get(area.annotation.id)?.thumbFileUrl"
                      (click)="contextImageClick(childContextImages.get(area.annotation.id))">
                    <div class="close" *ngIf="childContextImages.get(area.annotation.id)?.isClicked"
                      (click)="showChildContextImages(area, false)">x</div>
                  </div>
                </ng-container>
              </div>

              <div class="note-button-container" *ngFor="let area of activeSensitiveTags(); let i = index"
                [style]="getAreaSeverityIconStyle(area)">
                <p [matTooltip]="getTitle(area)" [style.background]="getBGColor(area)"
                  (click)="openSeverityLevels($event, i)" [class.disabled]="getReadOnlyForCurrentUser()"
                  *ngIf="area?.textConfig$.getValue().text !== ''" class="bubble-marker"></p>
              </div>

              <div *ngIf="uiService.project?.projectType === 'thermal' && area">
                <div class="label-button-container" [style]="getAreaTempratureLabelStyle(area)">
                  <!-- <mat-spinner *ngIf="area.temepratureGroup?.loading" [diameter]="10"></mat-spinner> -->
                  <div *ngIf="area.temperatureGroup?.minTemperature && area.temperatureGroup?.maxTemperature"
                    class="temp_label">
                    {{area.temperatureGroup.minTemperature}} °C - {{area.temperatureGroup.maxTemperature}} °C</div>
                  <div *ngIf="area.temperatureGroup?.temperature != null" class="temp_label">
                    {{area.temperatureGroup?.temperature}} °C</div>

                  <div
                    [style.background]="area.annotation.type =='hotspots' ? '#FFF': area.annotation.type =='coldspots' ? '#FFF' : getBgColor(area.annotation,true)"
                    [style.color]="area.annotation.type =='hotspots' ? 'red': area.annotation.type =='coldspots' ? 'blue' :  getBgColor(area.annotation,false)"
                    *ngIf="area.annotation?.isHover && area.annotation?.minTemperature && area.annotation?.maxTemperature"
                    class="temp_label">
                    {{area.annotation.minTemperature}} °C - {{area.annotation.maxTemperature}} °C</div>

                  <div
                    [style.background]="area.annotation.type =='hotspots' ? '#FFF': area.annotation.type =='coldspots' ? '#FFF' : getBgColor(area.annotation,true)"
                    [style.color]="area.annotation.type =='hotspots' ? 'red': area.annotation.type =='coldspots' ? 'blue' : getBgColor(area.annotation,false)"
                    *ngIf="area.annotation?.isHover && area.annotation?.temperature != null" class="temp_label">
                    {{area.annotation.temperature}} °C</div>
                </div>
              </div>


              <div class="note-button-container" *ngFor="let area of activeImgAreas; let i = index"
                [style]="getAreaNoteButtonStyle(area)">
                <!-- class="note-button mt-1" -->
                <div style="
                      width: 8px; height: 8px; 
                      cursor: pointer; 
                    " *ngIf="area.annotation && area.annotation.closedType != 'point'" (click)="openNotes($event, i)"
                  class="d-flex align-items-center justify-content-center" [class.active]="!!area.annotation?.note"
                  [class.disabled]="getReadOnlyForCurrentUser()">
                  <img *ngIf="!area.annotation?.note"
                    [src]="area.annotation?.noteImg ? area.annotation?.noteImg : 'assets/note-letter.png'" alt="N">
                  <img *ngIf="area.annotation?.note"
                    [src]="area.annotation?.noteImg ? area.annotation?.noteImg : 'assets/note-letter-fill.png'" alt="N">
                </div>
                <div
                  *ngIf="area.annotation?.isHover &&  area.annotation?.lastModifiedBy  && area.tag != 'thermalPolygon'"
                  [ngStyle]="{'color': '#3d3d3d','font-size': '10px','background-color':'rgba(255,255,255,0.5)', 'margin-top':'5px'}">
                  Created By {{area.annotation?.lastModifiedBy?.displayName}}</div>

                <div *ngIf="area.annotation?.isHover &&  area.annotation?.confidence"
                  [ngStyle]="{'color': '#3d3d3d','font-size': '10px','background-color':'rgba(255,255,255,0.5)', 'margin-top':'5px'}">
                  Confidence {{area.annotation?.confidence}} %</div>
              </div>

              <ko-stage #stage [config$]="configStage$" (onmouseup)="handleMouseUp($event)"
                (onmousedown)="handleMouseDown($event)" (onmousemove)="handleMouseMove($event)"
                (onclick)="handleClick($event)" (dblclick)="handleDoubleClick($event)" (mousewheel)="handleZoom($event)"
                (wheel)="handleZoom($event)" (touchmove)="handleZoom($event)" (ondragmove)="handleZoom($event)">
                <ko-layer>
                  <ko-image [config$]="configImg$"></ko-image>
                </ko-layer>

                <ko-layer>
                  <ko-line [config$]="selectionConfig$"></ko-line>
                  <ko-line [config$]="selectionLineConfig$"></ko-line>
                  <ko-line [config$]="selectionShadowConfig$"></ko-line>
                </ko-layer>

                <ko-layer>
                  <ng-container *ngFor="let area of activeImgAreas; let i = index">
                    <ko-line (onclick)="showChildContextImages(area, true)"
                      (onmouseover)="hoverChildContextImages(area, true);"
                      (onmouseout)="hoverChildContextImages(area, false);" (ondblclick)="openNotes($event, i)"
                      [config$]="area.config$">
                    </ko-line>
                    <ko-circle *ngIf="area.closedType === 'point'" (onmouseover)="hoverChildContextImages(area, true);"
                      (onmouseout)="hoverChildContextImages(area, false);" (ondblclick)="openNotes($event, i)"
                      [config$]="area.config$"></ko-circle>

                    <ko-group *ngIf="area.closedType != 'point'" [config$]="area.removeConfig$"
                      (onclick)="handleRemoveArea($event, i)" (onmousemove)="handleRemoveButtonFocusIn($event, i,area)"
                      (onmouseout)="handleRemoveButtonFocusOut($event, i,area)">
                      <ko-rect [config$]="area.removeConfigRect$"></ko-rect>
                      <ko-text [config$]="area.removeConfigText$"></ko-text>
                    </ko-group>


                    <ko-group *ngIf="area.tag && area.closedType != 'point'">
                      <ko-label [config$]="area.label$">
                        <ko-tag [config$]="area.tag$"></ko-tag>
                        <ko-text [config$]="area.textConfig$"></ko-text>
                      </ko-label>
                    </ko-group>
                    <ko-group *ngIf="!area.tag" [config$]="area.tagsGroupConfig$">
                      <ng-container *ngIf="!addContextEnabled$.value && !addThermalContextEnabled$.value">
                        <ng-container *ngFor="let config of tagsTextConfigs">
                          <ko-label [config$]="config.label$" (onclick)="handleTagSelect($event, i, config, area)">
                            <ko-tag [config$]="config.tag$"></ko-tag>
                            <ko-text [config$]="config.text$"></ko-text>
                          </ko-label>
                        </ng-container>
                      </ng-container>


                      <ng-container *ngIf="!addContextEnabled$.value && addThermalContextEnabled$.value && !isLoading">
                        <ng-container *ngFor="let config of thermalTemperatureTextConfigs">
                          <ko-label [config$]="config.label$" (onclick)="handleTagSelect($event, i, config, area)">
                            <ko-tag [config$]="config.tag$"></ko-tag>
                            <ko-text [config$]="config.text$"></ko-text>
                          </ko-label>
                        </ng-container>
                      </ng-container>
                    </ko-group>

                  </ng-container>
                </ko-layer>
              </ko-stage>

            </div>
          </div>

          <div class="carousel__btn -right">
            <button mat-icon-button (click)="activateImage(1)">
              <mat-icon fontSet="material-icons-outlined">
                arrow_forward_ios
              </mat-icon>
            </button>
          </div>
        </div>
        <!-- (scrolled)="onScroll()"
          (scrolledUp)="onScrollUp()" -->

        <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="1" [infiniteScrollThrottle]="50"
          [scrollWindow]="false" [ngClass]="{ 'v-slider-min': isVerticalScroll && isSidePanelEnable,
                                              'v-slider-max': isVerticalScroll && !isSidePanelEnable
}" style="    padding-right: 15px;" class="carousel__items scroller  zoom-item">
          <ng-container *ngIf="(images && !images.loading) && imagesList.length > 0">
            <div class="carousel__item" *ngFor="let image of filteredImages; let i = index"
              (click)="carausalActivateImage(image.id)">
              <img [src]="isColorImage ? image.colorThumbFileUrl :image.thumbFileUrl"
                [class.active]=" image.id == imageId" />
              <div class="carousel-filename">{{ image.fileName }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #loading *ngIf="!images.loading">
  <div class="h-100 w-100 flex align-center align-middle">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template> -->