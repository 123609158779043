import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {

  private ValidEmailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.ValidEmailPattern)]),
  });

  get email(): AbstractControl {
    return this.form.get('email');
  }

  public isSuccess$ = new BehaviorSubject<boolean>(null);

  private params: any;
  constructor(private router: Router, private route: ActivatedRoute, private auth: AngularFireAuth) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }

  ngAfterViewInit(): void {
    const buttons = document.getElementsByTagName('button');
    const idx = buttons.length - 1;
    const forgotPasswordBtn = buttons[idx];
    console.log({ forgotPasswordBtn });
    this.simulateClick(forgotPasswordBtn);
  }

  submit(): void {
    this.auth.sendPasswordResetEmail(this.email.value).then((res) => {
      this.isSuccess$.next(true);
    }).catch((error) => {
      this.isSuccess$.next(false);
    });
  }
  login() {
    this.router.navigateByUrl('/signin')
  }

  private simulateClick(control) {


    // if (document.all)
    // {
    //     control.click();
    // }
    // else
    // {
    const evObj = document.createEvent('MouseEvents');
    evObj.initMouseEvent('click', true, true, window, 1, 12, 345, 7, 220, false, false, true, false, 0, null);
    control.dispatchEvent(evObj);
    // }
  }

  public onResetPasswordRequested(): void {
    this.router.navigate(['forgot-password']);
  }

  public handleSuccess(): void {
    if (this.params.redirectUrl) {
      this.router.navigate([this.params.redirectUrl]);
    } else if (this.params.action === 'addToProject') {
      this.router.navigate(['/dashboard/projects'],
        {
          queryParams: {
            addToProject: this.params.project,
            role: this.params.role,
            readonly: this.params.readonly,
            id: this.params.id,
            dailyReport: this.params.dailyReport

          }
        });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  public navigateToSignUp(): void {
    this.router.navigate(['signup']);
  }

}
