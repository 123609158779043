import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../../services/backend.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { Project } from '../../projects/item/project.type';
import { IReportEditorData, ReportEditorDialogComponent } from '../../report-editor-dialog/report-editor-dialog.component';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITemplate } from '../../models/template.interface';
import { IInitialsData, InitialsDataModel } from '../../models/initials-data.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UiService } from '../../services/ui.service';
import * as moment from 'moment';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-daily-reports-initials',
  templateUrl: './daily-reports-initials.component.html',
  styleUrls: ['./daily-reports-initials.component.scss']
})
export class DailyReportsInitialsComponent implements OnInit {
 public projectId: string;
 public projects;
 projectUsers;
  @Output() removeInitials = new EventEmitter();
  projectInitialSubscribe;
  projectInitials: any[];
  asset;
  public isUpdateInitialsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initialsForm = new FormGroup({
    projectName: new FormControl(''),
    contractNumber: new FormControl(''),
    projectNumber: new FormControl(''),
    documentNumber: new FormControl(''),
    author: new FormControl(''),
    date: new FormControl(''),
    revision: new FormControl(''),
    approver: new FormControl(''),
    checker: new FormControl(''),
    clientLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    yourLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    id: new FormControl('')

  });
  get clientLogo(): AbstractControl { return this.initialsForm.controls.clientLogo; }
  get yourLogo(): AbstractControl { return this.initialsForm.controls.yourLogo; }
  get projectName(): AbstractControl { return this.initialsForm.controls.projectName; }
  get contractNumber(): AbstractControl { return this.initialsForm.controls.contractNumber; }
  get projectNumber(): AbstractControl { return this.initialsForm.controls.projectNumber; }
  get documentNumber(): AbstractControl { return this.initialsForm.controls.documentNumber; }
  get author(): AbstractControl { return this.initialsForm.controls.author; }
  get date(): AbstractControl { return this.initialsForm.controls.date; }
  get revision(): AbstractControl { return this.initialsForm.controls.revision; }
  get approver(): AbstractControl { return this.initialsForm.controls.approver; }
  get checker(): AbstractControl { return this.initialsForm.controls.checker; }
  get isAssetInfo(): AbstractControl { return this.initialsForm.controls.isAssetInfo; }
  get isCompanyInfo(): AbstractControl { return this.initialsForm.controls.isCompanyInfo }
  get isContactInfo(): AbstractControl { return this.initialsForm.controls.isContactInfo }

  constructor(
    public backendService: BackendService,
    private uiService: UiService,
    private generalService: GeneralService
  ) {

  }

  ngOnInit(): void {
  //  this.projectId = this.data.projectId;
 //   this.getProjectInitials(this.projectId);
 //   const project = this.projects.find(o => o.id == this.projectId)
  }


  imagesPreview(event, form: AbstractControl): void {
    if (event.target.files && event.target.files[0]) {
      let imageFile;
      const reader = new FileReader();
      reader.onload = (_event: any) => {
        imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        form.setValue(imageFile);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  revisedProjectInitials(data) {
    return this.backendService.revisedProjectInitials$(data, this.projectId)
  }

  public addProjectInitials(): void {
    const initialsData = new InitialsDataModel();
    const project = this.projects.find(o => o.id == this.uiService.selectedProjectId);
    const projectPeoples = project.people;
    const keys = Object.keys(projectPeoples);

    /* Default add reviewer,approver to initials window */
    let reviewer = [];
    let approver = [];
    keys.forEach(key => {
      if (projectPeoples[key].role == 'owner') {
        initialsData.author = this.getRoleName(key);
      }
      if (projectPeoples[key].role == 'reviewer') {
        reviewer.push(this.getRoleName(key));
      }
      if (projectPeoples[key].role == 'approver') {
        approver.push(this.getRoleName(key));
      }
    });
    initialsData.approver = approver.join(",");
    initialsData.checker = reviewer.join(",");
    initialsData.projectName = project.name;
    this.backendService.addProjectInitials$(initialsData, this.projectId)

  }

  public updateProjectInitials(): void {
    let initialsData = this.initialsForm.value;
    this.isUpdateInitialsLoading$.next(true)
    combineLatest([
      this.backendService.saveLogos(initialsData.clientLogo),
      this.backendService.saveLogos(initialsData.yourLogo)
    ]).pipe(
      switchMap(([clientLogo, yourLogo]) => {
        if (clientLogo) {
          initialsData.clientLogo = clientLogo;
        }
        if (yourLogo) {
          initialsData.yourLogo = yourLogo;
        }
        initialsData.isUpdate=true;
        if(initialsData.date){
          initialsData.date= moment(initialsData.date).format('DD-MMM-YYYY')
        }
        
        return this.backendService.updateProjectInitials$(this.initialsForm.value, this.projectId)
      })
    ).subscribe(response => {
      this.isUpdateInitialsLoading$.next(false)
      this.initialsForm.reset();
    }, error => {
      this.isUpdateInitialsLoading$.next(false)
    });
  }

 

  public getProjectInitials(projectId: string): void {
    this.projectInitialSubscribe = this.backendService.valueChangesProjectInitials(projectId).subscribe(response => {
      this.projectInitials = response;   
    });
  }

  getRoleName(id) {
    const user = this.projectUsers.find(o => o &&  o.uid == id)
    return user ? (user.fistName && user.lastName) ? user.fistName + ' ' + user.lastName : user.displayName : " ";
  }

  openForm(idx): void {
    this.initialsForm.patchValue(this.projectInitials[idx]);
  if(this.projectInitials[idx].date){
    this.initialsForm.controls['date'].setValue(moment(this.projectInitials[idx].date).format("YYYYY-MM-DD"))
  }
  }

  getReadOnlyForCurrentUser(project): boolean {
    const projectId = this.projectId;
    return this.generalService.getReadOnlyForCurrentUser(this.projects.find(o => o.id == projectId));
  }

  ngOnDestroy(): void {
    this.projectInitialSubscribe.unsubscribe();
  }

  getUserName(initials) {
    if (initials.createdBy != this.backendService.currentUser.uid) {
      let find = this.projectUsers.filter(o => o).find(o => o.uid == initials.createdBy)
      if (find) {
        return `(${(find.displayName || find.email)})`
      }
    }
  }


}
