
<div  class="form-container w-100 mt-2">

    <form class="d-flex flex-column  bg-white w-100 form-border" [formGroup]="initialsForm"
        style="position: relative;">
        <div style="position: absolute; right: 0; top: -60px;">
            <button mat-raised-button type="submit" color="primary"
                class="submit-button update-initials-button" [disabled]="isUpdateInitialsLoading$ | async"
                (click)="updateProjectInitials()">
                <!-- (click)="openEditor()" -->
                <span *ngIf="!(isUpdateInitialsLoading$ | async)" class="text-uppercase">Update</span>
                <span *ngIf="isUpdateInitialsLoading$ | async">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </span>
            </button>
        </div>
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="projectName" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="projectName"
                id="projectName" />
            <label for="projectName" [class.form-field__label_full]="projectName.value"
                class="form-field__label">Project name</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="contractNumber" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="contractNumber"
                id="contractNumber" />
            <label for="contractNumber" [class.form-field__label_full]="contractNumber.value"
                class="form-field__label">Contract number</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="projectNumber" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="projectNumber"
                id="projectNumber" />
            <label for="projectNumber" [class.form-field__label_full]="projectNumber.value"
                class="form-field__label">Project number</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="documentNumber" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="documentNumber"
                id="documentNumber" />
            <label for="documentNumber" [class.form-field__label_full]="documentNumber.value"
                class="form-field__label">Document number</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="author" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="author"
                id="author" />
            <label for="author" [class.form-field__label_full]="author.value"
                class="form-field__label">Author</label>
        </div>
    
        <!-- <div class="mb-3 d-flex align-items-center"> -->
        <!-- <input
                formControlName="date"
                type="text"
                maxLength="64"
                required
                class="form-control input-field report-name-input"
                placeholder="Date"
                name="date"
            /> -->
        <!-- </div> -->
        <!-- <div class="form-field">
            <mat-form-field class="form-field data-picker form-field__input  mb-3 d-flex align-items-center"
                appearance="fill">
                <input id="timepicker" #datepicker matInput formControlName="date" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <label for="timepicker" [class.form-field__label_full]="date.value || datepicker.value"
                for="timepicker" class="form-field__label">Date</label>
        </div> -->
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input  formControlName="date" type="date"
                class="form-control input-field report-name-input form-field__input" name="date"
                id="date" />
            <label for="date" [class.form-field__label_full]="date.value"
                class="form-field__label">Date</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="revision" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="revision"
                id="revision" />
            <label for="revision" [class.form-field__label_full]="revision.value"
                class="form-field__label">Revision</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="approver" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="approver"
                id="approver" />
            <label for="approver" [class.form-field__label_full]="approver.value"
                class="form-field__label">Approver</label>
        </div>
    
        <div class="form-field mb-3 d-flex align-items-center">
            <input formControlName="checker" type="text" maxLength="64" required
                class="form-control input-field report-name-input form-field__input" name="checker"
                id="checker" />
            <label for="checker" [class.form-field__label_full]="checker.value"
                class="form-field__label">Checker</label>
        </div>
    
        <div class="wrap-logo-inputs ml-0">
            <div class="wrap-logo-inputs__item">
                <div class="wrap-logo-inputs__label">Logo </div>
                <label class="wrap-logo-inputs__input logo-input">
                    <input (change)="imagesPreview($event, clientLogo)" type="file" name="clientLogo"
                        accept="image/*" #clientFileInput style="display: none;">
                    <img class="preview-image" [src]="clientLogo.value.link"
                        style="object-fit: contain; max-width: 70%; max-height: 70%">
    
                    <div *ngIf="!clientLogo.value.link" class="logo-input__icon">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M26.2537 3.75H3.75366C3.25638 3.75 2.77947 3.94754 2.42784 4.29917C2.07621 4.65081 1.87866 5.12772 1.87866 5.625V24.375C1.87866 24.8723 2.07621 25.3492 2.42784 25.7008C2.77947 26.0525 3.25638 26.25 3.75366 26.25H26.2537C26.7509 26.25 27.2279 26.0525 27.5795 25.7008C27.9311 25.3492 28.1287 24.8723 28.1287 24.375V5.625C28.1287 5.12772 27.9311 4.65081 27.5795 4.29917C27.2279 3.94754 26.7509 3.75 26.2537 3.75ZM3.75366 1.875C2.7591 1.875 1.80527 2.27009 1.10201 2.97335C0.39875 3.67661 0.00366211 4.63044 0.00366211 5.625V24.375C0.00366211 25.3696 0.39875 26.3234 1.10201 27.0266C1.80527 27.7299 2.7591 28.125 3.75366 28.125H26.2537C26.7461 28.125 27.2338 28.028 27.6887 27.8395C28.1437 27.6511 28.5571 27.3749 28.9053 27.0266C29.2535 26.6784 29.5298 26.265 29.7182 25.8101C29.9067 25.3551 30.0037 24.8675 30.0037 24.375V5.625C30.0037 4.63044 29.6086 3.67661 28.9053 2.97335C28.2021 2.27009 27.2482 1.875 26.2537 1.875H3.75366Z"
                                    fill="#CAD2D5" />
                                <path
                                    d="M19.9649 14.3362C20.1042 14.1973 20.2836 14.1057 20.4777 14.0744C20.6719 14.0432 20.871 14.0737 21.0468 14.1618L28.1287 17.8124V26.2499H1.87866V22.4999L6.83991 18.0862C6.99316 17.9335 7.1945 17.8385 7.4098 17.8173C7.62509 17.7961 7.84109 17.85 8.02116 17.9699L13.0087 21.2943L19.9649 14.338V14.3362Z"
                                    fill="#CAD2D5" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.44116 13.125C8.8105 13.125 9.17623 13.0523 9.51746 12.9109C9.85869 12.7696 10.1687 12.5624 10.4299 12.3012C10.6911 12.0401 10.8982 11.73 11.0396 11.3888C11.1809 11.0476 11.2537 10.6818 11.2537 10.3125C11.2537 9.94316 11.1809 9.57743 11.0396 9.2362C10.8982 8.89497 10.6911 8.58493 10.4299 8.32376C10.1687 8.0626 9.85869 7.85543 9.51746 7.71409C9.17623 7.57275 8.8105 7.5 8.44116 7.5C7.69524 7.5 6.97987 7.79632 6.45242 8.32376C5.92498 8.85121 5.62866 9.56658 5.62866 10.3125C5.62866 11.0584 5.92498 11.7738 6.45242 12.3012C6.97987 12.8287 7.69524 13.125 8.44116 13.125Z"
                                    fill="#CAD2D5" />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="30" height="30" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <span class="logo-input__text">{{clientLogo.value.name || 'Upload an image'}} </span>
    
                </label>
            </div>
    
        </div>
    
    </form>
    
    </div>